import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import { Map } from 'immutable'
import styled from 'styled-components'

import BarLoadingAnimation from 'components/BarLoadingAnimation'
import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SectionHeader from 'components/SectionHeader'
import UserWidget from 'components/UserWidget'
import SonraiBadge from 'components/SonraiBadge'

import UserActivityFilterContainer from './UserActivityFilterContainer'
import messages from './messages'
import { stripTags } from 'utils/sonraiUtils'

const styles = {
  card: {
    height: '100%',
  },
}

const propTypes = {
  push: PropTypes.func.isRequired,
  sonraiUsersLoading: PropTypes.bool,
  sonraiUsers: ImmutablePropTypes.map,
  userActivity: ImmutablePropTypes.contains({
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: ImmutablePropTypes.listOf(ImmutablePropTypes.map),
  }),
}

/**
 * From the prop passed from parent, create something presentable for the table
 */
function getDataForTable({ userActivity, sonraiUsers }) {
  return _.reverse(
    _.sortBy(
      userActivity
        .get('data')
        .toJS()
        .map(activity => ({
          user: sonraiUsers.get(activity.actorSrn, Map()).toJS(),
          eventName: activity.eventName,
          actionType: activity.actionType,
          createdDate: activity.createdDate,
          message: activity.message,
          actorSrn: activity.actorSrn,
        })),
      ['createdDate']
    )
  )
}

const TableContainer = styled.div`
  /* do this otherwise the table hangs off bottom of card */
  height: calc(100% - 30px);
`

/**
 * this the body of the list, showing loading, error or table
 */
export function UserActivityListBody(props) {
  if (props.userActivity.get('loading')) {
    return <BarLoadingAnimation />
  }

  if (props.userActivity.get('error')) {
    return (
      <div className="user-activity-error-message">
        <DynamicFormattedMessage {...messages.errorFetchingUserAcvitity} />
      </div>
    )
  }

  const tableData = getDataForTable(props)
  return (
    <TableContainer>
      <DataTable
        autosize={false}
        data={tableData}
        customGridProps={{
          groupUseEntireRow: true,
        }}
        customColumnConfig={{
          user: {
            valueGetter: params => {
              if (!params.data) {
                return ''
              }

              return params.data.user.name
                ? stripTags(params.data.user.name)
                : 'Sonrai'
            },
            // eslint-disable-next-line react/display-name
            cellRendererFramework: params => {
              if (!params.data) {
                return ''
              }

              if (!params.data.user.srn) {
                return <SonraiBadge />
              }

              return <UserWidget table srn={params.data.user.srn} />
            },
          },
          actorSrn: {
            hide: true,
          },
          message: {
            flex: 1,
            minWidth: 700,
          },
        }}
        customFilter={() => {
          return <UserActivityFilterContainer />
        }}
      />
    </TableContainer>
  )
}
UserActivityListBody.propTypes = propTypes

/**
 * This the main component with the layout and the title of the content
 */
export default function UserActivityList(props) {
  return (
    <BorderedCard style={styles.card}>
      <SectionHeader>
        <DynamicFormattedMessage {...messages.userActivityPageName} />
      </SectionHeader>
      <UserActivityListBody {...props} />
    </BorderedCard>
  )
}
UserActivityList.propTypes = propTypes
