import React from 'react'
import PropTypes from 'prop-types'
import { FILTER_DATE_FORMAT } from 'appConstants'
import moment from 'moment'
import { DateRangePicker as DateRangePickerLib } from 'react-dates'

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      startDate: props.startDate ? moment(props.startDate) : null,
      endDate: props.endDate ? moment(props.endDate) : null,
      focusedInput: null,
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.startDate !== this.props.startDate) {
      this.setState({
        startDate: this.props.startDate ? moment(this.props.startDate) : null,
      })
    }

    if (oldProps.endDate !== this.props.endDate) {
      this.setState({
        endDate: this.props.startDate ? moment(this.props.endDate) : null,
      })
    }
  }

  onDatesChange = ({ startDate, endDate }) => {
    const formattedStartDate = startDate
      ? startDate.format(FILTER_DATE_FORMAT)
      : null

    const formattedEndDate = endDate ? endDate.format(FILTER_DATE_FORMAT) : null

    this.props.onChange({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    })

    this.setState({
      startDate,
      endDate,
    })
  }

  render() {
    return (
      <div
        style={{
          padding: '0.75rem 0.75rem 0.25rem 0.75rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <DateRangePickerLib
          noBorder
          startDate={this.state.startDate}
          startDateId="daterangepickerstartdate"
          endDate={this.state.endDate}
          endDateId="daterangepickerenddate"
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          onDatesChange={this.onDatesChange}
          isOutsideRange={date => moment(date).isAfter(moment().endOf('day'))}
          displayFormat={'MMMM D YYYY'}
        />
      </div>
    )
  }
}

DateRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}

export default DateRangePicker
