import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'
import styled from 'styled-components'

import Button from 'components/Button'
import DateRangePicker from 'components/DateRangePicker'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SelectBar from 'components/SelectBar'
import { BlockShimmer } from 'components/ShimmerLoader'
import TextLink from 'components/TextLink'

import { initialState as reduxInitialState } from './reducer'
import messages from './messages'

// this component has some styles for laying out the form
const FilterFormContainer = styled.div`
  padding: 0.5rem;
  width: 300px;

  & .title-container {
    align-items: center;
    display: flex;
    font-size: 0.9rem;
    justify-content: space-between;

    & .title-text {
      font-weight: 400;
      font-size: 1.1rem;
    }
  }

  & .controls-container {
    text-align: right;
    width: 100%;

    & .close-button {
      font-size: 0.9rem;
      cursor: pointer;
      color: #ababab;
      margin: 0rem 0.75rem 0rem 0rem;
    }
  }
`

// this component has some styles for a field in our form
const FilterFormField = styled.div`
  margin: 1rem 0rem;

  & .field-title {
    font-weight: 400;
  }
`

/**
 * take sonraiUsers from property and make a list of select options
 */
function getUserOptions({ sonraiUsers }) {
  return Object.values(
    sonraiUsers
      .map(user => ({
        label: user.get('name'),
        value: user.get('srn'),
      }))
      .toJS()
  )
}

/**
 * take the allOptions data from property and make a list of select options
 */
function getActionTypeOptions({ allActionTypes }) {
  return allActionTypes.map(value => ({
    label: value,
    value: value,
  }))
}

function getEventNameOptions({ allEventNames }) {
  return allEventNames.map(value => ({
    label: value,
    value: value,
  }))
}

/**
 * The values in the redux store have a bit different form than what works
 * nicely for this form, so we use this method to transform form values from
 * this component into redux form
 */
function toQueryFilters({ dateRange, users, actionTypes, eventNames }) {
  const value = {
    fromDate: { value: dateRange.startDate },
    toDate: { value: dateRange.endDate },
  }

  if (actionTypes && actionTypes.length > 0) {
    value.actionType = {
      values: actionTypes.map(at => at.value),
    }
  }

  if (users && users.length > 0) {
    value.actorSrn = {
      values: users.map(user => user.value),
    }
  }

  if (eventNames && eventNames.length > 0) {
    value.eventName = {
      values: eventNames.map(eventName => eventName.value),
    }
  }

  return value
}

/**
 * The values in the redux store have a bit different form than what works
 * nicely for this form, so we use this method to transform redux values
 * into values for this form
 */
function initialStateFromProps({ userActivityFilter, sonraiUsers }) {
  const dateRange = {
    startDate: userActivityFilter.getIn(['fromDate', 'value']),
    endDate: userActivityFilter.getIn(['toDate', 'value']),
  }

  let users = null
  if (userActivityFilter.getIn(['actorSrn', 'values'])) {
    users = userActivityFilter
      .getIn(['actorSrn', 'values'])
      .map(srn => sonraiUsers.get(srn))
      .filter(_.identity)
      .map(user => ({ value: user.get('srn'), label: user.get('name') }))
      .toJS()
  }

  let actionTypes = null
  if (userActivityFilter.getIn(['actionType', 'values'])) {
    actionTypes = userActivityFilter
      .getIn(['actionType', 'values'])
      .map(actionType => ({
        label: actionType,
        value: actionType,
      }))
      .toJS()
  }

  let eventNames = null
  if (userActivityFilter.getIn(['eventName', 'values'])) {
    eventNames = userActivityFilter
      .getIn(['eventName', 'values'])
      .map(eventName => ({
        label: eventName,
        value: eventName,
      }))
      .toJS()
  }

  return { dateRange, users, actionTypes, eventNames }
}

/**
 * this is the filter form component. it is displayed when the user clicks to
 * open the form popover
 */
export default function UserActvityFilterForm(props) {
  const [dateRange, setDateRange] = useState({})
  const [users, setUsers] = useState(null)
  const [eventNames, setEventNames] = useState(null)
  const [actionTypes, setActionTypes] = useState(null)

  // set the state for our form based on the current filter state from redux
  useEffect(() => {
    const initialState = initialStateFromProps(props)
    setDateRange(initialState.dateRange)
    setUsers(initialState.users)
    setActionTypes(initialState.actionTypes)
    setEventNames(initialState.eventNames)
  }, [props.userActivityFilter])

  // if the user clicks reset, use the initial redux state for this form
  function resetFilters() {
    const resetInitialState = initialStateFromProps({
      ...props,
      userActivityFilter: reduxInitialState.get('userActivityFilter'),
    })
    setDateRange(resetInitialState.dateRange)
    setUsers(resetInitialState.users)
    setActionTypes(resetInitialState.actionTypes)
    setEventNames(resetInitialState.eventNames)
  }

  // when they click apply, sync our form values with redux
  function onApply() {
    const newFilterValue = toQueryFilters({
      dateRange,
      users,
      eventNames,
      actionTypes,
    })
    props.setFilterValues(newFilterValue)
    props.toggleShowFilterPanel()
  }

  return (
    <FilterFormContainer>
      <div className="title-container">
        <div className="title-text">
          <DynamicFormattedMessage {...messages.filterFormTitle} />
        </div>
        <TextLink color="primary" onClick={resetFilters}>
          <DynamicFormattedMessage {...messages.filterFormReset} />
        </TextLink>
      </div>
      <FilterFormField>
        <div className="field-title">
          <DynamicFormattedMessage {...messages.filterFormLabelDateRange} />
        </div>
        <DateRangePicker
          onChange={setDateRange}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
        />
      </FilterFormField>
      <FilterFormField>
        <div className="field-title">
          <DynamicFormattedMessage {...messages.filterFormLabelUser} />
        </div>
        {props.sonraiUsersLoading ? (
          <BlockShimmer />
        ) : (
          <SelectBar
            isClearable
            isMulti
            placeholder="All Users..."
            options={getUserOptions(props)}
            value={users}
            onChange={setUsers}
          />
        )}
      </FilterFormField>
      <FilterFormField>
        <div className="field-title">
          <DynamicFormattedMessage {...messages.filterFormLabelEventName} />
        </div>
        <SelectBar
          isClearable
          isMulti
          placeholder="All Action Types..."
          options={getEventNameOptions(props)}
          value={eventNames}
          onChange={setEventNames}
        />
      </FilterFormField>
      <FilterFormField>
        <div className="field-title">
          <DynamicFormattedMessage {...messages.filterFormLabelType} />
        </div>
        <SelectBar
          isClearable
          isMulti
          placeholder="All Action Types..."
          options={getActionTypeOptions(props)}
          value={actionTypes}
          onChange={setActionTypes}
        />
      </FilterFormField>
      <div className="controls-container">
        <TextLink
          className="close-button"
          onClick={props.toggleShowFilterPanel}
        >
          <DynamicFormattedMessage {...messages.filterFormClose} />
        </TextLink>
        <Button color="primary" onClick={onApply}>
          <DynamicFormattedMessage {...messages.filterFormApply} />
        </Button>
      </div>
    </FilterFormContainer>
  )
}

UserActvityFilterForm.propTypes = {
  allActionTypes: ImmutablePropTypes.listOf(PropTypes.string),
  userActivityFilter: ImmutablePropTypes.map,
  setFilterValues: PropTypes.func,
  sonraiUsersLoading: PropTypes.bool,
  sonraiUsers: ImmutablePropTypes.map,
  toggleShowFilterPanel: PropTypes.func.isRequired,
}
