import React, { useState } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import _ from 'lodash'

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'

import styled from 'styled-components'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

import Icon from 'components/Icon'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'

import UserActvityFilterForm from './UserActivityFilterForm'

import { setFilterValues } from './actions'

import { initialState as reduxInitialState } from './reducer'

import {
  selectSonraiUsers,
  selectSonraiUsersLoading,
} from 'containers/SonraiData/selectors'
import {
  selectAllActionTypes,
  selectAllEventNames,
  selectUserActivityFilter,
} from './selectors'

const AnchorIconContainer = styled.div`
  margin: 1rem 0rem 0rem 0.75rem;
`

/**
 * this is the form popover component with the icon to toggle if it is open
 */
export function UserActivityFilterContainer(props) {
  const hasFiltersApplied = !_.isEqual(
    reduxInitialState.get('userActivityFilter').toJS(),
    props.userActivityFilter.toJS()
  )

  const [showFilterPanel, setShowFilterPanel] = useState(false)
  function toggleShowFilterPanel() {
    setShowFilterPanel(!showFilterPanel)
  }

  return (
    <Popover
      isOpen={showFilterPanel}
      onToggle={toggleShowFilterPanel}
      position="right-start"
    >
      <PopoverAnchor>
        <AnchorIconContainer>
          <Icon
            fa
            name={hasFiltersApplied ? 'filter-solid' : 'filter'}
            style={{
              color: props.theme.primary,
            }}
          />
        </AnchorIconContainer>
      </PopoverAnchor>
      <PopoverBody>
        <UserActvityFilterForm
          {...props}
          toggleShowFilterPanel={toggleShowFilterPanel}
        />
      </PopoverBody>
    </Popover>
  )
}
UserActivityFilterContainer.propTypes = {
  theme: themeShape,
  userActivityFilter: ImmutablePropTypes.map,
}

const mapStateToProps = createStructuredSelector({
  allActionTypes: selectAllActionTypes,
  allEventNames: selectAllEventNames,
  sonraiUsers: selectSonraiUsers,
  sonraiUsersLoading: selectSonraiUsersLoading,
  userActivityFilter: selectUserActivityFilter,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setFilterValues }, dispatch)

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default compose(
  withConnect,
  themeable
)(UserActivityFilterContainer)
