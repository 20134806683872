import { defineMessages } from 'react-intl'

export default defineMessages({
  errorFetchingUserAcvitity: {
    id: 'app.containers.UserActivity.errorFetchingUserAcvitity',
    defaultMessage: 'There was an error fetching the user activity data',
  },
  filterFormApply: {
    id: 'app.containers.UserActivity.filterFormApply',
    defaultMessage: 'Apply',
  },
  filterFormClose: {
    id: 'app.containers.UserActivity.filterFormClose',
    defaultMessage: 'Close',
  },
  filterFormTitle: {
    id: 'app.containers.UserActivity.filterFormTitle',
    defaultMessage: 'Filters',
  },
  filterFormReset: {
    id: 'app.containers.UserActivity.filterFormReset',
    defaultMessage: 'Reset Filters',
  },
  filterFormLabelDateRange: {
    id: 'app.containers.UserActivity.filterFormLabelDateRange',
    defaultMessage: 'Date Range',
  },
  filterFormLabelEventName: {
    id: 'app.containers.UserActivity.filterFormEventName',
    defaultMessage: 'Event Name',
  },
  filterFormLabelType: {
    id: 'app.containers.UserActivity.filterFormLabelType',
    defaultMessage: 'Action Type',
  },
  filterFormLabelUser: {
    id: 'app.containers.UserActivity.filterFormLabelUser',
    defaultMessage: 'User',
  },
  userActivityPageName: {
    id: 'app.containers.UserActivity.userActivity',
    defaultMessage: 'User Activity',
  },
  userManagmentPageName: {
    id: 'app.containers.UserActivity.userManagmentPageName',
    defaultMessage: 'User Managment',
  },
})
