import {
  FETCH_USER_ACTIVITY,
  FETCH_USER_ACTIVITY_SUCCES,
  FETCH_USER_ACTIVITY_ERROR,
  SET_FILTER_VALUES,
} from './constants'
import { createAction } from 'redux-actions'

export const fetchUserActivity = createAction(FETCH_USER_ACTIVITY)
export const fetchUserActivitySuccess = createAction(FETCH_USER_ACTIVITY_SUCCES)
export const fetchUserActivityError = createAction(FETCH_USER_ACTIVITY_ERROR)
export const setFilterValues = createAction(SET_FILTER_VALUES)
