import { all, put, select, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import { fetchUserActivitySuccess, fetchUserActivityError } from './actions'
import { FETCH_USER_ACTIVITY, SET_FILTER_VALUES } from './constants'
import { USER_ACTIVITY_QUERY } from './queries'
import { selectUserActivityFilter } from './selectors'

function* fetchUserActivity() {
  try {
    const client = yield getClient()
    const filter = yield select(selectUserActivityFilter)
    const response = yield client.query({
      query: USER_ACTIVITY_QUERY,
      variables: { filter },
    })

    const path = ['data', 'SonraiAuditLogs', 'items']
    const activities = _.get(response, path, null)
    if (activities === null) {
      throw new Error(`response did not contain value at path ${path}`)
    }
    yield put(fetchUserActivitySuccess({ activities }))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('An error happened fetching user activity', e)
    yield put(fetchUserActivityError())
  }
}

function* userActivitySaga() {
  yield all([
    takeLatest(FETCH_USER_ACTIVITY, fetchUserActivity),
    takeLatest(SET_FILTER_VALUES, fetchUserActivity),
  ])
}

export default userActivitySaga
