import React from 'react'

import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import TextLink from 'components/TextLink'

import messages from './messages'

/**
 * this component has the breadcrumb users will see so they know where they are
 * and they can click on part of it to go back to where they were before
 */
export default function UserAuditLogsBreadcrumb() {
  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <TextLink
          color="primary"
          to={{
            pathname: '/App/UserManagement',
          }}
        >
          <DynamicFormattedMessage {...messages.userManagmentPageName} />
        </TextLink>
      </BreadcrumbItem>
      <BreadcrumbItem>
        <DynamicFormattedMessage {...messages.userActivityPageName} />
      </BreadcrumbItem>
    </Breadcrumb>
  )
}

UserAuditLogsBreadcrumb.propTypes = {}
