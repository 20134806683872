import { fromJS } from 'immutable'
import moment from 'moment'
import { handleActions } from 'redux-actions'

import {
  FETCH_USER_ACTIVITY,
  FETCH_USER_ACTIVITY_SUCCES,
  FETCH_USER_ACTIVITY_ERROR,
  SET_FILTER_VALUES,
} from './constants'

var hardcodedActionTypes = [
  'Create',
  'Read',
  'Update',
  'Delete',
  'Assign',
  'Login',
]

var hardcodedEventNames = [
  'UserLogin',
  'CreatePolicyEvalLog',
  'CreateControlFrameworkEvalLog',
  'CreateCollectorConfiguration',
  'UpdateCollectorConfiguration',
  'DeleteCollectorConfiguration',
  'DeleteCollectorConfigurations',
  'CreateDataClassificationConfig',
  'UpdateDataClassificationConfig',
  'DeleteDataClassificationConfig',
  'setMonitor',
  'setChangeDetectionProperties',
  'removeChangeDetectionProperties',
  'resetLockState',
  'setImportance',
  'bulkSetImportance',
  'bulkDismissAlert',
  'dismissAlert',
  'CreateBot',
  'UpdateBot',
  'DeleteBot',
  'SyncBot',
  'CreateBotAssignments',
  'RemoveBotAssignments',
  'UpdateSonraiUsers',
  'CreateSonraiInvites',
  'UpdateSonraiInvites',
  'DeleteSonraiInvites',
  'CreateSonraiPendingRoleAssignments',
  'DeleteSonraiPendingRoleAssignments',
  'CreateSonraiRoles',
  'UpdateSonraiRoles',
  'CreateSonraiRoleAssignments',
  'DeleteSonraiRoleAssignments',
  'UpdateSonraiCurrentUsers',
  'AddTag',
  'CreateTag',
  'UpdateCdconfig',
  'UpdateResource',
  'UpdateTag',
  'DeleteTag',
  'CreateReport',
  'CreateQueryvariable',
  'CreateSavedquery',
  'CreateSwimlane',
  'CreateControlpolicy',
  'CreateSolutioncard',
  'CreateControlframework',
  'CreatePlatformcloudaccount',
  'CreateWidget',
  'CreateList',
  'CreatePlatformaccount',
  'CreateSearchv2',
  'CreateSearch',
  'UpdateReport',
  'UpdateQueryvariable',
  'UpdateSavedquery',
  'UpdateSwimlane',
  'UpdateContent',
  'UpdatePlatformuser',
  'UpdateControlpolicy',
  'UpdateSolutioncard',
  'UpdateControlframework',
  'UpdatePlatformcloudaccount',
  'UpdateWidget',
  'UpdateList',
  'UpdatePlatformaccount',
  'UpdateSearchv2',
  'UpdateSearch',
  'DeleteReport',
  'DeleteSavedquery',
  'DeleteSwimlane',
  'DeleteControlpolicy',
  'DeleteSolutioncard',
  'DeleteControlframework',
  'DeletePlatformcloudaccount',
  'DeleteWidget',
  'DeleteList',
  'DeletePlatformaccount',
  'DeleteSearchv2',
  'DeleteSearch',
  'CloneSwimlane',
  'CloneControlpolicy',
  'CloneControlframework',
]

export const initialState = fromJS({
  allActionTypes: hardcodedActionTypes,
  allEventNames: hardcodedEventNames,
  userActivity: {
    loading: true,
    error: false,
    data: null,
  },
  userActivityFilter: {
    // default date range = 1 week
    toDate: { value: moment().format('YYYY-MM-DD') },
    fromDate: {
      value: moment().subtract(1, 'week').format('YYYY-MM-DD'),
    },
  },
})

const userActivityReducer = handleActions(
  {
    [FETCH_USER_ACTIVITY]: state =>
      state
        .setIn(['userActivity', 'loading'], true)
        .setIn(['userActivity', 'error'], false),
    [FETCH_USER_ACTIVITY_ERROR]: state =>
      state
        .setIn(['userActivity', 'loading'], false)
        .setIn(['userActivity', 'error'], true),
    [FETCH_USER_ACTIVITY_SUCCES]: (state, { payload }) =>
      state
        .setIn(['userActivity', 'loading'], false)
        .setIn(['userActivity', 'error'], false)
        .setIn(['userActivity', 'data'], fromJS(payload.activities)),
    [SET_FILTER_VALUES]: (state, { payload }) =>
      state
        .set('userActivityFilter', fromJS(payload))
        .setIn(['userActivity', 'loading'], true),
  },
  initialState
)

export default userActivityReducer
