import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import styled from 'styled-components'

import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import { createStructuredSelector } from 'reselect'

import injectSaga from 'utils/injectSaga'
import injectReducer from 'utils/injectReducer'
import reducer from './reducer'
import saga from './sagas'

import { Helmet } from 'react-helmet'
import RedirectWithoutPermission from 'containers/PermissionChecker/RedirectWithoutPermission'
import UserActivityBreadcrumb from './UserActivityBreadcrumb'
import UserActivityList from './UserActivityList'

import { fetchUserActivity } from './actions'

import {
  selectSonraiUsers,
  selectSonraiUsersLoading,
} from 'containers/SonraiData/selectors'
import { selectUserActivity } from './selectors'

const PageContainer = styled.div`
  padding: 1rem;
  height: calc(100vh - 60px /* header height */);
  display: grid;
  grid-template-rows: 1rem auto;
  grid-row-gap: 1rem;
`

export class UserActivityPage extends Component {
  componentDidMount() {
    this.props.fetchUserActivity()
  }

  render() {
    return (
      <PageContainer>
        <Helmet title={`Sonrai - User Activity`} />
        <RedirectWithoutPermission permissionName="view.audit" />
        <UserActivityBreadcrumb {...this.props} />
        <UserActivityList {...this.props} />
      </PageContainer>
    )
  }
}

UserActivityPage.propTypes = {
  // ~ bound action creators ~
  fetchUserActivity: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,

  // ~ props from redux store ~
  userSelectors: ImmutablePropTypes.iterable,
}

const mapStateToProps = createStructuredSelector({
  sonraiUsers: selectSonraiUsers,
  sonraiUsersLoading: selectSonraiUsersLoading,
  userActivity: selectUserActivity,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUserActivity,
      push,
    },
    dispatch
  )

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({ key: 'UserActivity', reducer })
const withSaga = injectSaga({ key: 'UserActivity', saga: saga })

export default compose(
  withConnect,
  withReducer,
  withSaga
)(UserActivityPage)
