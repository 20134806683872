import { createSelector } from 'reselect'
import { initialState } from './reducer'

const selectStateForThisPage = state => state.get('UserActivity', initialState)

export const selectUserActivity = createSelector(
  selectStateForThisPage,
  state => state.get('userActivity')
)

export const selectUserActivityFilter = createSelector(
  selectStateForThisPage,
  state => state.get('userActivityFilter')
)

export const selectAllActionTypes = createSelector(
  selectStateForThisPage,
  state => state.get('allActionTypes')
)

export const selectAllEventNames = createSelector(
  selectStateForThisPage,
  state => state.get('allEventNames')
)
