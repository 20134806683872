import gql from 'graphql-tag'

export const USER_ACTIVITY_QUERY = gql`
  query fetchUserActivity($filter: SonraiAuditLogFilter) {
    SonraiAuditLogs(where: $filter) {
      items {
        srn
        resourceId
        type
        actorSrn
        createdDate
        resourceSrn
        actionType
        eventName
        message
      }
    }
  }
`
